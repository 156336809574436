<script setup lang="ts">
    import type { InlineNavWidgetData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    const props = defineProps<IWidgetProps<InlineNavWidgetData>>();
    const { data, getValueSequence } = useWidget(props);
    const { resolveUrlLink } = useURL();

    const imageSrc = (value: AssetIcon | undefined) => {
        if (!value) return;
        const imgSrc = value.data.src;
        return imgSrc;
    };

    const items = computed(() => {
        if (!data.value) return [];

        return getValueSequence(data.value).map((i) => {
            const { url, target } = resolveUrlLink(i.link);
            return {
                title: i.title,
                icon: i.visual?.data?.icon,
                image: imageSrc(i.visual),
                url,
                target,
            };
        });
    });
</script>
<template>
    <org-inline-nav-item-slider
        v-if="items.length > 0"
        :inline-nav-items="items"
        :background="data.style?.background_color"
        :intro="data.intro" />
</template>
